<script setup>
import autoAnimate from '@formkit/auto-animate';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/Components/Table/Table.vue';
import Icon from '@/Components/Icon.vue';
import Right from '@i/expand_more-right.svg';
import ShiftSummary from './ShiftSummary.vue';
import RejectedApplicationForm from './RejectedApplicationForm.vue';
import ShiftFeed from './ShiftFeed.vue';

const { t } = useI18n();
const route = inject('route');


const props = defineProps({
    shift: Object,
    liked_flexer_ids: Object,

    sortBy: {
        type: String,
        default: '',
    },
    sortByDesc: {
        type: String,
        default: '',
    },
    dropdownOpen: Boolean,
    isOrange: Boolean,
});

const summary = ref();
const show = ref(false);

onMounted(() => {
    autoAnimate(summary.value);
});

const selectedApplication = ref({});

const showRejectedApplicationForm = ref(false);
const openRejectedApplicationForm = (application) => {
    showRejectedApplicationForm.value = true;
    selectedApplication.value = application;
};


const userType = { freelancer: t('Freelancer'), temp_worker: t('Temp Worker') };

const emit = defineEmits(['update:sortBy', 'update:orderDir']);

const tableSettings = [
    { label: t('Name'), sortBy: 'flexer.first_name', width: '25%', cell: 'NameAndLike' },
    { label: t('Type'), sortBy: 'flexer.type', width: '15%' },
    { label: t('Reviews'), sortBy: 'flexer.received_reviews_avg_rating', cell: 'Rating', width: '15%' },
    { label: t('Price per hour'), width: '15%' },
    { label: t('Distance'), width: '15%' },
    { label: '', width: '2%', cell: 'Cta' },
    { label: '', width: '2%', cell: 'Remove' },
    { label: '', width: '2%', cell: 'Arrow' },
];

const applications = computed(() => {
    const applications = [
        ...props.shift.child_shifts
            .filter((shift) => shift.selected_application)
            .map((shift) => shift.selected_application),
        props.shift.selected_application ? props.shift.selected_application : null,
        ...props.shift.applications,
    ];

    return applications.filter((application) => application);
});

const tableData = computed(() =>
    applications.value
        .map((application) => {


            const canRejectApplication = true;

            return {
                id: application.id,
                colorCode: application.status === 'accepted' ? '#1BB21B' : '#F89B29',

                rowData: [
                    [
                        application.flexer.display_name,
                        'bold',
                        application.flexer.id,
                        props.liked_flexer_ids.includes(application.flexer.id),
                    ],
                    userType[application.flexer.type],
                    [application.flexer.received_reviews_avg_rating, application.flexer.received_reviews_count],
                    '€' + (application.hourly_cost / 100).toFixed(2),

                    application.distance / 1000 + ' km',
                    [application, props.shift],
                    canRejectApplication,
                    application.flexer.deleted_at ? '' : route('staff.flexers.edit', { id: application.flexer.id }),
                ],
            };
        })
);


const whichButton = ({ row, column, id, cell }) => {
    // See resources/js/Pages/Location/Planning/Partials/Shift.vue for original
    if (cell === 'Remove') {

        const possibleShiftRejectStatuses = ['open', 'filled'];
        const possibleApplicationRejectStatuses = ['applied', 'invited', 'accepted', 'confirmation_required'];

        let application = applications.value.find((app) => app.id == id);

        const canRejectApplication =
            possibleApplicationRejectStatuses.includes(application.status) &&
            possibleShiftRejectStatuses.includes(props.shift.status);

        if (canRejectApplication) {

        }
        openRejectedApplicationForm(application);

    }
};

</script>

<template>
    <div ref="summary" class="">
        <ShiftSummary :shift="shift">
            <div class="w-5 cursor-pointer group" @click="show = !show">
                <Icon
                    :icon="Right"
                    class="relative right-0 float-right w-3 h-12 transition-all duration-300 -translate-x-1 group-hover:-rotate-90"
                    :class="{ '-rotate-90 ': show }"
                    v-if="!dropdownOpen"
                />
            </div>
        </ShiftSummary>

        <div v-if="show || dropdownOpen" class="grid gap-2">
            <Table
                class="mb-8"
                :tableSettings="tableSettings"
                :data="tableData"
                @cellClicked="whichButton"
            >

            </Table>
        </div>

        <ShiftFeed :shift="shift" />
    </div>
    <RejectedApplicationForm v-model:showing='showRejectedApplicationForm' :application='selectedApplication'
                             :shift='shift' />
</template>
